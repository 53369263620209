
.triangle {
  box-sizing: border-box;
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}
.triangle-4 {
  width: 60px;
  height: 30px;
  border-bottom: solid 30px rgb(200,30,50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
.triangle-5 {
  width: 60px;
  height: 30px;
  border-top: solid 30px rgb(200,30,50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mint-main {
  background-color: #000000;
}

.right {
  background-color: #000000;
  border-radius: 10px;
}
.container {
  padding: 0px;
}
.main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  border-radius: 10px;
}

.right-text {
  color: #cacaca;
  font-size: 14px;
}
.right-text span {
  color: white;
  font-weight: 600;
}
.right-title {
  font-size: 42px;
  font-weight: 700;
  color: white;
}
.right-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.mint-title {
  font-size: 40px;
  font-weight: 700;
  color: white;
}
.btn-plus,
.btn-minus {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5b4ffa;
  position: relative;
  cursor: pointer;
}

.fa-circle-caret-up,
.fa-circle-caret-down {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
}
.input {
  padding: 16px 18px;
  background-color: #000000;
  border-radius: 20px;
  border: none;
  color: white;
  width: 10rem;
  outline: none;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.mint-btn-submit {
  width: 20rem;
  padding: 12px;
  background-color: #ed8cc0 !important;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  border: none;
  outline: none;
  border-radius: 10px;
  /* transition: 1s; */
}
.mint-btn-submit:focus {
  box-shadow: none;
  outline: none;
}
.mint-btn-submit:hover {
  /* background-color: #b818ff; */
  /* box-shadow: 0.2rem 0.2rem #ffffff, -0.2rem -0.2rem #5b4ffa; */
}

@media (max-width: 600px) {
  .row {
    --bs-gutter-x: 0rem !important;
    --bs-gutter-y: 0 !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 450px) {
  .mint-btn-submit {
    width: 97%;
  }
  .btn-plus,
  .btn-minus {
    width: 30px;
    height: 30px;
  }
  .fa-plus,
  .fa-minus {
    font-size: 20px;
  }
}
